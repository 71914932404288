<template>
  <div class="row justify-content-center">
  <div class="form-signin col-6">
      <label for="adlogin" class="sr-only">Username</label>
      <input type="text" id="username" v-on:keyup.enter="login()" v-model="username" class="form-control" placeholder="Username" required autofocus>
      <label for="inputPassword" class="sr-only">Password</label>
      <input type="password" id="inputPassword" v-on:keyup.enter="login()" v-model="password" class="form-control" placeholder="Password" required>
      <button v-if="!$parent.backload" class="btn btn-lg btn-primary btn-block" @click="login()">Sign in</button>
  </div>
  </div>
</template>

<script>
export default {
  data(){return{
    username: '',
    password: ''
  }},
  methods: {
    login(){
      if (this.username=='' || this.password=='') {this.loading='Error: Fill username and password!'; return}
      localStorage.removeItem('HldmarkAccessToken')
      this.backend.post("auth/login",{username: this.username, password: this.password}).then(resp => 
      {
        if (resp.data.access_token)
         {
           localStorage.setItem('HldmarkAccessToken',resp.data.access_token);
           this.$emit('login', true)
           this.$router.push("/")
           return
         }
      });
    }
  }
}
</script>

<style scoped>
.form-signin {
  padding: 15px;
  font-size: 13px;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 13px;
}
.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}


</style>